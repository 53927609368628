<template>
  <div v-if="showResetButton">
    <v-btn @click="resetPassword" tabindex="6">{{ showButtonText }}</v-btn>
  </div>
  <div v-else>
    <v-row :dense="denseRow" :align="prependLabels ? 'baseline' : null">
      <v-col v-if="prependLabels" cols="12" :sm="12-cols">
        <label class="required_star">Wachtwoord:</label>
      </v-col>
      <v-col cols="12" :sm="cols">
        <v-text-field v-bind="$attrs" v-on="$listeners"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :value="value"
                  :label="labels ? 'Wachtwoord' : null"
                  @input="setPassword"
                  :rules="passwordRules"
                  @click:append="showPassword = !showPassword"
                  @keyup.native.enter="toElement('confirmPassword')"
                  :tabindex="tabindex" required/>
      </v-col>
      <v-col v-if="prependLabels" cols="12" :sm="12-cols">
        <label class="required_star">Bevestig wachtwoord:</label>
      </v-col>
      <v-col cols="12" :sm="cols">
        <v-text-field
                      v-model.trim="confirmPassword"
                      v-bind="$attrs"
                      :value="null"
                      id="confirmPassword"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      :label="labels ? 'Bevestig wachtwoord' : null"
                      :rules="confirmPasswordRules"
                      @click:append="showPassword = !showPassword"
                      :tabindex="tabindex+1"
                      required
                      @keyup.native.enter="processPassword"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getPasswordRules} from "@/shared/utils/inputRulesUtils";

export default {
  name: "PasswordInput",
  inheritAttrs: false,
  props: {
    value: String,
    showButton: {
      type: Boolean,
      default: false
    },
    tabindex: Number,
    showButtonText: String,
    cols: {
      default: 6
    },
    denseRow: {
      default: false
    },
    labels: {
      type: Boolean,
      default: true
    },
    prependLabels: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showResetButton: this.showButton,
      confirmPassword: null,
      showPassword: false,
    }
  },
  methods: {
    setPassword(value) {
      this.$emit('input', value.trim())
    },
    resetPassword() {
      this.showResetButton = false
    },
    toElement(element) {
      document.getElementById(element).focus()
    },
    processPassword() {
      this.$emit('enter-pressed')
    }
  },
  computed: {
    passwordRules() {
      return getPasswordRules();
    },
    confirmPasswordRules() {
      let rules = getPasswordRules()
      if (this.value !== this.confirmPassword) {
        rules.push(v => (!!v && v !== this.confirmPassword) || 'De wachtwoorden komen niet overeen')
      }
      return rules
    }
  }
}
</script>
