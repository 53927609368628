<template>
    <UserDetailItemEditCard confirmation-message="Wachtwoord werd succesvol gewijzigd"
                        title="Bewerk wachtwoord"
                        :detailViewRouteLocation="{name: routeNames.USER_DETAIL, params: { userId: userId}}"
                        update-store-action="authModule/updateOtherPassword"
                        :update-store-action-payload="{email: getEmail, newPassword: this.password}"
                        :userId="userId" :promises.sync="promises">
      <template v-slot:form-fields>
        <v-row>
          <v-col cols="12">
            <PasswordInput v-model="password" cols="6" persistent-placeholder/>
          </v-col>
        </v-row>
      </template>
    </UserDetailItemEditCard>
  </template>
  
  <script>
  
  import routeNames from "@/router/RouteNames";
  import UserDetailItemEditCard from "@/views/user/forms/UserDetailItemEditCard.vue"
  import PasswordInput from "@/components/useroverview/PasswordInput.vue";
  
  export default {
    name: "UserEditPassword",
    components: {
      UserDetailItemEditCard,
      PasswordInput
    },
    props: {
      userId: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        routeNames: routeNames,
        roles: null,
        password: null,
        contactInformation: null,
        promises: [
          this.$store.dispatch('usersModule/fetchUserContactInformation', this.userId)
            .then((contactInformation) => this.contactInformation = contactInformation)
        ]
      }
    },
    computed: {
      getEmail() {
        return this.contactInformation?.email
      }
    }
  }
  </script>
  